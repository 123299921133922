<template>
  <div v-if="favorite.type === 'lesson_package'">
    <div class="card" style="margin-bottom: 20px">
      <div
        class="card-content"
        style="
          display: flex;
          justify-content: start;
          gap: 16px;
          padding: 30px 30px;
        "
      >
        <div>
          <img
            v-if="favorite.resourceData.thumbnail"
            :src="favorite.resourceData.thumbnail"
            style="width: 180px; height: 150px; object-fit: cover"
          />
          <img
            v-else
            src="./images/default-avatar.svg"
            style="width: 180px; height: 150px; object-fit: cover"
          />
        </div>
        <div>
          <div style="display: flex; justify-content: start; gap: 0.5em">
            <div style="display: flex; justify-content: start; gap: 0.5em">
              <div
                @click="likeLessonPackage(favorite.resourceData.id)"
                style="
                  font-size: 1em;
                  display: flex;
                  align-items: center;
                  margin-right: 5px;
                  font-family: 'League Spartan';
                  color: #003d6a;
                  text-decoration: none;
                  cursor: pointer;
                "
              >
                <img
                  height="20"
                  src="/images/liked-01.svg"
                  alt="ai-assisted"
                  style="margin-right: 0.3em"
                />
                <span
                  style="font-size: 0.8em; color: #e00069; margin-top: 0.2em"
                  >Like</span
                >
              </div>
            </div>
          </div>
          <h2 :title="favorite.resourceData.title" style="font-size: 24px">
            {{ favorite.resourceData.title }}
          </h2>
          <p style="font-size: 0.7em; color: #333333; font-weight: 400">
            {{ favorite.resourceData.short_description }}
          </p>
        </div>
      </div>

      <!-- Divider (underline effect) -->
      <hr
        style="
          border-top: 1px solid #cccccc;
          border-right: none;
          border-bottom: none;
          border-left: none;
          border-image: initial;
          margin: 0px 30px 10px;
        "
      />

      <!-- Footer with buttons aligned to the right -->
      <div
        class="card-footer"
        style="padding: 0px 30px 15px; position: relative"
      >
        <div style="display: flex; gap: 10px; justify-content: flex-end">
          <a
            :href="`/lesson-packages/${favorite.resourceData.id}`"
            target="_blank"
            class="button details-btn"
            style="border-radius: 15px; padding: 10px 20px"
            >View Resources</a
          >
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="card" style="margin-bottom: 20px">
      <div
        class="card-content"
        style="
          display: flex;
          justify-content: start;
          gap: 16px;
          padding: 30px 30px;
        "
      >
        <div>
          <img
            v-if="
              favorite.resourceData.image_thumbnail &&
              favorite.resourceData.image_thumbnail.length > 0
            "
            :src="favorite.resourceData.image_thumbnail[0]"
            style="width: 180px; height: 150px; object-fit: cover"
          />
          <img
            v-else
            src="./images/default-avatar.svg"
            style="width: 180px; height: 150px; object-fit: cover"
          />
        </div>
        <div>
          <div style="display: flex; justify-content: start; gap: 0.5em">
            <div style="display: flex; justify-content: start; gap: 0.5em">
              <div
                @click="likeStemStudio(favorite.resourceData.makercart_id)"
                style="
                  font-size: 1em;
                  display: flex;
                  align-items: center;
                  margin-right: 5px;
                  font-family: 'League Spartan';
                  color: #003d6a;
                  text-decoration: none;
                  cursor: pointer;
                "
              >
                <img
                  height="20"
                  src="/images/liked-01.svg"
                  alt="ai-assisted"
                  style="margin-right: 0.3em"
                />
                <span
                  style="font-size: 0.8em; color: #e00069; margin-top: 0.2em"
                  >Like</span
                >
              </div>
            </div>
          </div>
          <h2
            :title="favorite.resourceData.makercart_activity"
            style="font-size: 24px"
          >
            {{ favorite.resourceData.makercart_activity }}
          </h2>
          <p style="font-size: 0.7em; color: #333333; font-weight: 400">
            {{ favorite.resourceData.activity_description }}
          </p>
        </div>
      </div>

      <!-- Divider (underline effect) -->
      <hr
        style="
          border-top: 1px solid #cccccc;
          border-right: none;
          border-bottom: none;
          border-left: none;
          border-image: initial;
          margin: 0px 30px 10px;
        "
      />

      <!-- Footer with buttons aligned to the right -->
      <div
        class="card-footer"
        style="padding: 0px 30px 15px; position: relative"
      >
        <div style="display: flex; gap: 10px; justify-content: flex-end">
          <a
            v-if="favorite.resourceData.stem_makercart_order_id"
            class="button details-btn"
            style="cursor: pointer; border-radius: 15px; padding: 10px 20px"
            @click="
              updateVisibilityModalMakerCartDetail(
                true,
                favorite.resourceData.makercart_id,
                favorite.resourceData.stem_makercart_order_id
              )
            "
            >More Details</a
          >
          <a
            href="#"
            class="button disabled"
            style="
              background-color: rgb(204, 204, 204);
              cursor: not-allowed;
              border-radius: 15px;
              padding: 10px 20px;
            "
            @mouseover="showTooltip = true"
            @mouseleave="showTooltip = false"
            >View Resources</a
          >
        </div>
        <div
          v-if="showTooltip"
          class="tooltip"
          style="
            border: 1px solid #cccccc;
            position: absolute;
            background-color: #f0f0f0;
            padding: 10px;
            border-radius: 4px;
            top: 100%;
            right: 0;
            font-size: 0.75em;
            z-index: 100;
          "
        >
          You'll receive an email once the resources for this activity are ready
          for download.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue"
defineProps({
  favorite: Object
})

const emit = defineEmits([
  "like",
  "like-stem-studio",
  "updateVisibilityModalMakerCartDetail"
])

function likeLessonPackage(id) {
  emit("like", id) // Emit the event to the parent
}

function likeStemStudio(id) {
  emit("like-stem-studio", id) // Emit the event to the parent
}

function updateVisibilityModalMakerCartDetail(
  visible,
  id,
  stem_makercart_order_id
) {
  emit("updateVisibilityModalMakerCartDetail", {
    visible,
    id,
    stem_makercart_order_id
  })
}

const showTooltip = ref(false)
</script>
